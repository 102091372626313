import { request } from 'umi';

export async function getNotificationAll(params: API.NotificationGetParams) {
  return request<API.PageDataType<API.NotificationSummary>>('/notification/all', {
    method: 'get',
    params,
  });
}

export async function getNotificationDeficit(params: { id: string }) {
  return request<API.DeficitSetting>('/notification/deficit', {
    method: 'get',
    params,
  });
}

export async function putNotificationDeficit(
  data: API.NotificationDeficitPutParams,
  params: { id: string },
) {
  return request<API.DeficitSetting>('/notification/deficit', {
    method: 'put',
    params,
    data,
  });
}

export async function getNotificationReport(params: { id: string }) {
  return request<API.ReportSetting>('/notification/report', {
    method: 'get',
    params,
  });
}

export async function putNotificationReport(
  data: API.NotificationReportPutParams,
  params: { id: string },
) {
  return request('/notification/report', {
    method: 'put',
    params,
    data,
  });
}

export async function postNotificationReport(
  data: API.NotificationReportPostParams,
  params: { id: string },
) {
  return request('/notification/report/manual', {
    method: 'post',
    params,
    data,
  });
}
