import {
  deleteAccountApi,
  getAccountAllApi,
  getAccountListApi,
  postAccountApi,
  putAccountApi,
  syncAccountApi,
} from '@/services/account';
import { promiseCompleteCall } from '@/utils/utils';
import { useState } from 'react';

export default () => {
  const [accountAll, setAccountAll] = useState<API.PageDataType<API.Account>>({
    page: 1,
    total: 1,
    data: [],
  });
  const [accountList, setAccountList] = useState<API.PageLoading<API.AccountList>>({
    page: 1,
    total: 1,
    data: [],
    loading: false,
  });

  const getAccountList = async () => {
    setAccountList((prev) => ({ ...prev, loading: true }));
    return getAccountListApi()
      .then((res) => {
        setAccountList((prev) => ({ ...prev, ...res }));
        return res;
      })
      .finally(() => {
        setAccountList((prev) => ({ ...prev, loading: false }));
      });
  };

  const getAccountAll = async (params: API.AccountGetParametersType = {}) => {
    return getAccountAllApi(params).then((res) => {
      setAccountAll(res);
      return res;
    });
  };

  const deleteAccount = async (id: string) => {
    return deleteAccountApi(id).then((res) => {
      return res;
    });
  };

  const createAccount = async (params: API.AccountPostParametersType) => {
    return postAccountApi(params).then((res) => {
      return res;
    });
  };

  const updateAccount = async (params: API.AccountPutParametersType) => {
    return putAccountApi(params).then((res) => {
      return res;
    });
  };

  const syncAccount = async (params: API.AccountSyncGetParametersType) => {
    return syncAccountApi(params).then((res) => {
      return res;
    });
  };

  return {
    accountAll,
    getAccountAll,
    accountList,
    getAccountList,
    deleteAccount: promiseCompleteCall(deleteAccount, getAccountList),
    createAccount: promiseCompleteCall(createAccount, getAccountList),
    updateAccount: promiseCompleteCall(updateAccount, getAccountList),
    syncAccount,
  };
};
