import initializeTagmanager from '@/tracking/dataLayer/initTagmanager';
import initGoogleAnalytics from './dataLayer/initGoogleAnalytics';

export default function initTagHanlder() {
  const configs = {
    saya: {
      ga: 'G-MR2DGTKZFG',
    },
  };

  const substringFromDomain = window.location.host.split('.')[0];
  const { ga: selectGa, gtm: selectGTM } = configs?.[substringFromDomain] || {};
  if (selectGa) {
    initGoogleAnalytics(selectGa);
  }
  if (selectGTM) {
    initializeTagmanager(selectGTM);
  }
  if (!(selectGa || selectGTM) && TAG_MANAGER_ID) {
    initializeTagmanager(TAG_MANAGER_ID);
  }
}
