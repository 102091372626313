import {
  deleteCompany as deleteCompanyAPI,
  getCompanyAll as getCompanyAllApi,
  getCompanyList as getCompanyListApi,
  postCompany,
  putCompany,
} from '@/services/company';
import { promiseCompleteCall } from '@/utils/utils';
import { useState } from 'react';

export default () => {
  const [companyAll, setCompanyAll] = useState<API.PageDataType<API.Company>>({
    page: 1,
    total: 1,
    data: [],
  });
  const [companyList, setCompanyList] = useState<API.PageLoading<API.CompanyList>>({
    page: 1,
    total: 1,
    data: [],
    loading: false,
  });

  const getCompanyList = async () => {
    setCompanyList((prev) => ({ ...prev, loading: true }));
    return getCompanyListApi()
      .then((res) => {
        setCompanyList((prev) => ({ ...prev, ...res }));
        return res;
      })
      .finally(() => {
        setCompanyList((prev) => ({ ...prev, loading: false }));
      });
  };

  const getCompanyAll = async (params: API.CompanyListGetParametersType = {}) => {
    return getCompanyAllApi(params).then((res) => {
      setCompanyAll(res);
      return res;
    });
  };

  const createCompany = async (params: API.CompanyPostParametersType) => {
    return postCompany(params).then((res) => {
      return res;
    });
  };

  const deleteCompany = async (id: string) => {
    return deleteCompanyAPI(id).then((res) => {
      return res;
    });
  };

  const updateCompany = async (params: API.CompanyPutParametersType) => {
    return putCompany(params).then((res) => {
      return res;
    });
  };

  return {
    getCompanyAll,
    companyAll,
    getCompanyList,
    companyList,
    createCompany: promiseCompleteCall(createCompany, getCompanyList),
    deleteCompany: promiseCompleteCall(deleteCompany, getCompanyList),
    updateCompany: promiseCompleteCall(updateCompany, getCompanyList),
  };
};
