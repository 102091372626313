import React from 'react';

import RightContent from '@/components/RightContent';
import type { Settings as LayoutSettings } from '@ant-design/pro-layout';
import type { RunTimeLayoutConfig } from 'umi';
// import Footer from '@/components/Footer';
import TrackingInit from '@/tracking/TrackingInit';

import layoutSettings from '../config/layout';
import themeSettings, { advanceTheme } from '../config/theme';
// @ts-ignore
import { getUser } from '@/services/user';
import { history } from 'umi';

import type { ResourcesRoleActions } from '@/utils/featureMappingRole';
import { filterByTeamsPermission, getUserResourcePermission } from '@/utils/featureMappingRole';
import { ThemeProvider } from 'styled-components';
import { request } from './services/interceptor';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import FetchListingContainer from './components/FetchListingContainer';

import { ConfigProvider } from 'antd';
import 'antd/dist/reset.css';
import { GlobalStyle } from './Global.style';

if (SENTRY_DSN_SERVER) {
  Sentry.init({
    dsn: SENTRY_DSN_SERVER,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.2,
    environment: GOOGLE_CLOUD_PROJECT,
  });
}

// can view these pages without sso token
const unprotectedPages = ['/login', '/signup', '/resetpassword'];

export async function getInitialState(): Promise<{
  settings?: Partial<LayoutSettings>;
  currentUser?: API.User;
  role?: ResourcesRoleActions;
  fetchUserInfo?: () => Promise<API.User | undefined>;
}> {
  const fetchUserInfo = async () => {
    return getUser().catch(() => {
      return undefined;
    });
  };

  const { pathname } = history.location;

  if (!unprotectedPages.includes(pathname)) {
    const currentUser = await fetchUserInfo();
    const role = getUserResourcePermission(currentUser?.roles || []);
    const filterRole = filterByTeamsPermission(role, currentUser?.teams);
    return {
      currentUser,
      settings: layoutSettings || {},
      role: filterRole,
    };
  }

  return {
    settings: layoutSettings || {},
  };
}

export function rootContainer(container: React.ReactNode) {
  return (
    // useModel is not exist when component running on rootContainer
    <TrackingInit>
      <ConfigProvider theme={themeSettings}>
        <ThemeProvider theme={{ advanceTheme, ...themeSettings }}>
          <>
            <GlobalStyle />
            {container}
          </>
        </ThemeProvider>
      </ConfigProvider>
    </TrackingInit>
  );
}

export const layout: RunTimeLayoutConfig = ({ initialState }) => {
  return {
    rightContentRender: () => <RightContent />,
    disableContentMargin: false,
    onPageChange: () => {
      window.scrollTo({
        top: 0,
      });
      const { pathname } = history.location;
      if (!unprotectedPages.includes(pathname) && !initialState?.currentUser?.id) {
        getUser().catch((err) => console.log(err));
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-shadow
    childrenRender: (children) => {
      return (
        <ThemeProvider theme={themeSettings}>
          <FetchListingContainer>{children}</FetchListingContainer>
        </ThemeProvider>
      );
    },
    // force change the menu type when collapsed changed
    // custom 403 page
    unAccessible: <div>unAccessible</div>,
    ...initialState?.settings,
  };
};

export { request };
