import { createGlobalStyle } from 'styled-components';

declare module 'styled-components' {
  export interface DefaultTheme {
    token: {
      colorPrimary: string;
      colorBgLayout: string;
      colorTextDescription: string;
      colorInfoTextHover: string;
      colorTextSecondary: string;
      colorFillSecondary: string;
    };
    advanceTheme: Record<string, string | number>;
  }
}

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-weight: 700;
    font-family: Inter;
    font-style: normal;
    src: url('../fonts/Inter/Inter-Bold.ttf') format('truetype');
    font-display: fallback;
  }

  @font-face {
    font-weight: 500;
    font-family: Inter;
    font-style: normal;
    src: url('../fonts/Inter/Inter-Medium.ttf') format('truetype');
    font-display: fallback;
  }

  @font-face {
    font-weight: 400;
    font-family: Inter;
    font-style: normal;
    src: url('../fonts/Inter/Inter-Regular.ttf') format('truetype');
    font-display: fallback;
  }
  @font-face {
    font-weight: 700;
    font-family: Noto Sans TC;
    font-style: normal;
    src: url('../fonts/Noto_Sans_TC/NotoSansTC-Bold.otf') format('opentype');
    font-display: fallback;
  }

  @font-face {
    font-weight: 500;
    font-family: Noto Sans TC;
    font-style: normal;
    src: url('../fonts/Noto_Sans_TC/NotoSansTC-Medium.otf') format('opentype');
    font-display: fallback;
  }

  @font-face {
    font-weight: 400;
    font-family: Noto Sans TC;
    font-style: normal;
    src: url('../fonts/Noto_Sans_TC/NotoSansTC-Regular.otf') format('opentype');
    font-display: fallback;
  }

  body {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    #root-master,
    #root {
      height: 100%;
    }

    a {
      color: ${({ theme }) => theme.token.colorPrimary};  
      text-decoration: none;
      background-color: transparent;
      outline: none;
      cursor: pointer;
      transition: color 0.3s;
    }

    .ant-space {
      width: inherit;

      .ant-space-item {
        width: inherit;
      }
    }

    .ant-pro-table-column-setting-overlay {
      .ant-popover-inner-content {
        width: 360px;
      }
    }

    .ant-table-wrapper .ant-table.ant-table-middle .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
      margin-inline: 40px 0px;
    }

    .ant-pro-card-statistic-content .ant-statistic-content-value-int {
      font-size: inherit;
    }

    /* custom style */
    .ant-pro-layout .ant-pro-layout-bg-list {
      background: linear-gradient(#ffffff, #f0f5fc 28%);
    }
    .ant-pro-global-header-logo {
      margin-inline: 16px;
    }
    .ant-statistic .ant-statistic-title, .ant-pro-card-statistic .ant-statistic-title {
      color: ${({ theme }) => theme.token.colorTextSecondary};  
    }
    .ant-statistic .ant-statistic-content {
      font-size: 30px;
    }
    .ant-card .ant-card-head {
      font-weight: 500;
    }
    .ant-table-wrapper .ant-table-thead >tr>th, .ant-table-wrapper .ant-table-thead >tr>td {
      background: ${({ theme }) => theme.token.colorFillSecondary};  
    }
    .ant-table-wrapper .ant-table-cell-scrollbar:not([rowspan]) {
      box-shadow: initial;
    }
    h4.ant-typography, div.ant-typography-h4, .ant-typography h4 {
      margin-bottom: 0;
    }
    .ant-pro-table-column-setting-list-item-title {
      max-width: 260px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .ant-table-expanded-row .ant-pro-card .ant-pro-card-body {
      padding-inline-end: 0px;
    }
    .ant-table-wrapper .ant-table.ant-table-middle .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
      margin-inline-start: 12px;
    }
    /* custom style end */

    @media print {
      html,
      body {
        height: initial !important;
        overflow: initial !important;
      }
    }
    @media print {
      .page-break {
        margin-top: 1rem;
        page-break-inside: avoid;
      }
    }

    // 兼容IE11
    @media screen and(-ms-high-contrast: active), (-ms-high-contrast: none) {
      body .ant-design-pro > .ant-layout {
        min-height: 100vh;
      }
    }
  }
`;
