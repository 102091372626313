import { Settings as LayoutSettings } from '@ant-design/pro-layout';
import logo from '../public/nav_logo.png';

const Settings: LayoutSettings & {
  pwa?: boolean;
  logo?: string;
  locale?: boolean;
  siderWidth?: number;
} = {
  title: '',
  locale: true,
  siderWidth: 208,
  navTheme: 'light', // light, dark
  layout: 'mix',
  contentWidth: 'Fluid',
  fixedHeader: false,
  fixSiderbar: true,
  colorWeak: false,
  iconfontUrl: '',
  logo: logo,
};

export default Settings;
