import { request } from 'umi';

export async function getUser() {
  return request<API.User>('/user/me');
}

export async function putUser(params: API.UpdateUserParams) {
  return request<API.User>('/user/me', {
    method: 'put',
    data: params,
  });
}

export async function getUserAll(params?: API.UserGetParamtersType) {
  return request<API.PageLoading<API.User>>('/user/all', {
    method: 'get',
    params,
  });
}
