// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import model_1 from '/root/src/models/useAccount';
import model_3 from '/root/src/models/useAgency';
import model_5 from '/root/src/models/useBank';
import model_6 from '/root/src/models/useBudget';
import model_7 from '/root/src/models/useCompany';
import model_8 from '/root/src/models/useContact';
import model_9 from '/root/src/models/useDeposit';
import model_10 from '/root/src/models/useNotification';
import model_12 from '/root/src/models/useRole';
import model_13 from '/root/src/models/useSpending';
import model_14 from '/root/src/models/useSystem';
import model_15 from '/root/src/models/useTeam';
import model_16 from '/root/src/models/useTransaction';
import model_11 from '/root/src/models/useOrder';
import model_18 from '/root/src/.umi-production/plugin-initialState/@@initialState';
import model_4 from '/root/src/models/useAuth';
import model_17 from '/root/src/models/useUser';
import model_2 from '/root/src/models/useAdmin';

export const models = {
model_1: { namespace: 'useAccount', model: model_1 },
model_3: { namespace: 'useAgency', model: model_3 },
model_5: { namespace: 'useBank', model: model_5 },
model_6: { namespace: 'useBudget', model: model_6 },
model_7: { namespace: 'useCompany', model: model_7 },
model_8: { namespace: 'useContact', model: model_8 },
model_9: { namespace: 'useDeposit', model: model_9 },
model_10: { namespace: 'useNotification', model: model_10 },
model_12: { namespace: 'useRole', model: model_12 },
model_13: { namespace: 'useSpending', model: model_13 },
model_14: { namespace: 'useSystem', model: model_14 },
model_15: { namespace: 'useTeam', model: model_15 },
model_16: { namespace: 'useTransaction', model: model_16 },
model_11: { namespace: 'useOrder', model: model_11 },
model_18: { namespace: '@@initialState', model: model_18 },
model_4: { namespace: 'useAuth', model: model_4 },
model_17: { namespace: 'useUser', model: model_17 },
model_2: { namespace: 'useAdmin', model: model_2 },
} as const
