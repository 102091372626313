import type React from 'react';
import { useEffect } from 'react';

import useHistoryChange from '@/hooks/useHistoryChange';
import { pushLayerInit } from '@/tracking/dataLayer/initEvent';
import initTagHanlder from './initTagHandler';

const TrackingInit: React.FC<{ children: React.ReactElement }> = ({ children }) => {
  useEffect(() => {
    initTagHanlder();
    pushLayerInit();
  }, []);
  useHistoryChange();
  return children;
};

export default TrackingInit;
