import { request } from 'umi';

export async function putUser(params: API.AdminUserPutParamtersType) {
  const { id, ...restParams } = params;
  return request('/admin/user', {
    method: 'put',
    params: { id },
    data: restParams,
  });
}

export async function postUser(params: API.AdminUserPostParamtersType) {
  return request('/admin/user', {
    method: 'post',
    data: params,
  });
}

export async function deletetUser(params: API.AdminUserDeleteParamtersType) {
  return request('/admin/user', {
    method: 'delete',
    params,
  });
}
export async function putTeam(params: API.AdminTeamPutParamtersType) {
  const { id, ...restParams } = params;
  return request('/admin/team', {
    method: 'put',
    params: { id },
    data: restParams,
  });
}

export async function postTeam(params: API.AdminTeamPostParamtersType) {
  return request('/admin/team', {
    method: 'post',
    data: params,
  });
}

export async function putTeamAddMemeber(params: API.AdminTeamMemberPutParamtersType) {
  const { id, members } = params;
  return request('/admin/team/add_member', {
    method: 'put',
    params: { id },
    data: members,
  });
}

export async function putTeamRemoveMemeber(params: API.AdminTeamMemberPutParamtersType) {
  const { id, members } = params;
  return request('/admin/team/remove_member', {
    method: 'put',
    params: { id },
    data: members,
  });
}
