const userInfoManager = () => {
  let info: EVENT.LoginInfo = {
    userId: '',
    name: '',
    email: '',
  };
  const initUser = (userData: EVENT.LoginInfo) => {
    info = userData;
  };
  const getUser = () => {
    return info;
  };
  return { initUser, getUser };
};

export default userInfoManager();
