import { useCallback } from 'react';
import { history, useModel } from 'umi';

import { postLogout } from '@/services/auth';
import { resetTrackSession } from '@/tracking/user';

const useLogout = () => {
  const { initialState, setInitialState } = useModel('@@initialState');

  const logout = useCallback(async () => {
    await postLogout();
    if (initialState) {
      setInitialState({ ...initialState, currentUser: undefined });
    }

    // Note: There may be security issues, please note
    if (window.location.pathname !== '/login') {
      history.push({
        pathname: '/login',
      });
    }
    resetTrackSession();

    // return `true` can disable awesome-dashboard's redirect to `sso.${pathName}/login`
    return true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, initialState]);

  return logout;
};

export default useLogout;
