import { useEffect } from 'react';
import { useModel } from 'umi';

import { pushIdentifyUserLogin } from '@/tracking/user';
import userInfoManager from '@/utils/userInfoManager';

const useInitUser = () => {
  const { initialState } = useModel('@@initialState');

  useEffect(() => {
    if (initialState?.currentUser) {
      const { id, email, name } = initialState?.currentUser;
      userInfoManager.initUser({ userId: id, name, email });
      pushIdentifyUserLogin();
    }
  }, [initialState?.currentUser]);
};

export default useInitUser;
