import { getRoleAll as getRoleAllApi } from '@/services/role';
import { useState } from 'react';

export default () => {
  const [allRoles, setAllRoles] = useState<API.StateLoading<API.Role>>({
    data: [],
    loading: false,
  });

  const getAllRoles = async () => {
    setAllRoles((prev) => ({ ...prev, loading: true }));
    return getRoleAllApi()
      .then((res) => {
        const { data } = res;
        setAllRoles((prev) => ({ ...prev, data }));
        return res;
      })
      .finally(() => {
        setAllRoles((prev) => ({ ...prev, loading: false }));
      });
  };

  return {
    getAllRoles,
    allRoles,
  };
};
