import { request } from 'umi';

import { convertTodayToCurrentTimeUTC, decorateDateValueToUTC } from '@/utils/timeConvert';

export async function getOrderList(params: API.OrderGetParametersType) {
  console.log(`params`, params);
  let overwriteEndDate = {};
  if (params?.end) {
    const convertEndDate = convertTodayToCurrentTimeUTC(params?.end);
    if (convertEndDate) {
      overwriteEndDate = { end: convertEndDate };
    }
  }
  return request<API.PageDataType<API.Order>>('/order/all', {
    params: { ...decorateDateValueToUTC(params, ['start', 'end']), ...overwriteEndDate },
  });
}

export async function getOrder(id: string) {
  return request<API.Order>('/order', { params: { id } });
}

export async function postOrder(data: API.OrderPostParametersType) {
  return request('/order', {
    method: 'post',
    data: decorateDateValueToUTC(data, ['advancePaymentDue']),
  });
}

export async function putOrder(params: API.OrderPutParametersType) {
  const { id, ...data } = params;
  return request('/order', {
    method: 'put',
    params: { id },
    data: decorateDateValueToUTC(data, ['advancePaymentDue']),
  });
}

export async function deleteOrder(id: string) {
  return request('/order', {
    params: { id },
    method: 'delete',
  });
}

export async function putOrderTransation(data: API.OrderTransactionPutParametersType, id: string) {
  return request('/order/add_transaction', {
    method: 'put',
    params: { id },
    data,
  });
}

export async function putOrderSubmit(data: API.OrderSubmitPutParametersType, id: string) {
  return request('/order/submit', {
    method: 'put',
    params: { id },
    data,
  });
}

export async function putOrderReview(data: API.OrderReviewPutParametersType, id: string) {
  return request('/order/review', {
    method: 'put',
    params: { id },
    data,
  });
}

export async function downloadOrderList(params: API.OrderDownloadParametersType) {
  const endDateIsCurrentDate = params?.end && params?.end.isSame(new Date(), 'day');
  const overwriteEndDate =
    params?.end && endDateIsCurrentDate ? { end: params?.end.utc().format() } : {};
  return request<API.DownloadType>('/order/download/csv', {
    responseType: 'blob',
    params: { ...decorateDateValueToUTC(params, ['start', 'end']), ...overwriteEndDate },
  });
}

export const postReceiptEmail = async (params: API.OrderPostReceiptEmailParametersType) => {
  const { id, toEmails, note } = params;
  return request('/order/receipt_email', {
    method: 'post',
    params: { id },
    data: { toEmails, note },
  });
};
