import useLogout from '@/hooks/useLogout';
import { pushEventVisitFQAPage, pushEventVisitSupportPage } from '@/tracking/commonEvent';
import { HeaderRight } from 'awesome-dashboard';
import React from 'react';
import { setLocale, useIntl, useModel } from 'umi';

const GlobalHeaderRight: React.FC = () => {
  const { initialState } = useModel('@@initialState');
  const { formatMessage } = useIntl();
  const logout = useLogout();

  if (!initialState || !initialState.currentUser) {
    return null;
  }

  const handleTracking = (type: string) => {
    const trackingConfig = {
      contact: pushEventVisitSupportPage,
      faq: pushEventVisitFQAPage,
    };
    const getTrackCallback = trackingConfig[type];
    if (typeof getTrackCallback === 'function') {
      getTrackCallback();
    }
  };

  return (
    <HeaderRight
      isShowLang
      layout="top"
      versionTag={REACT_APP_ENV}
      onUserlogout={logout}
      formatMessage={formatMessage}
      currentUser={{ name: initialState?.currentUser?.name || '' }}
      services={[]}
      onUpdateLocale={setLocale}
      onTracking={handleTracking}
      avatarHiddenList={['setting']}
      exploreHiddenList={['home']}
      infoHiddenList={['faq']}
    />
  );
};
export default GlobalHeaderRight;
