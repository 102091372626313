/* eslint-disable @typescript-eslint/no-unused-expressions */

import userInfoManager from '@/utils/userInfoManager';
import { pushDataLayer } from './dataLayer/initEvent';

export const getCommonVariables = () => ({ ...userInfoManager.getUser() });

// event
export const pushEventPageView = () => {
  pushDataLayer({ event: 'pageView' });
};

// User clicks link to customer support page
export const pushEventVisitSupportPage = () => {
  pushDataLayer({ event: 'visitSupport', ...getCommonVariables() });
};

// User clicks link to FAQ Page
export const pushEventVisitFQAPage = () => {
  pushDataLayer({ event: 'visitFAQ', ...getCommonVariables() });
};

// User encounters any type of error
export const pushEventEncounterError = ({
  url,
  message,
  status,
}: {
  url: string;
  message: string;
  status: number;
}) => {
  pushDataLayer({
    event: 'error',
    errorNote: `API: ${url}, status: ${status}, message: ${message}`,
    ...getCommonVariables(),
  });
};
