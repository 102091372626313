import {
  deletetUser as deleteUserAPI,
  postTeam,
  postUser,
  putTeam,
  putTeamAddMemeber,
  putTeamRemoveMemeber,
  putUser,
} from '@/services/admin';
import { promiseCompleteCall } from '@/utils/utils';
import { useModel } from 'umi';

export default () => {
  const { getAllUsers } = useModel('useUser') as {
    getAllUsers: () => Promise<API.PageLoading<API.User>>;
  };
  const { getAllTeams } = useModel('useTeam') as {
    getAllTeams: () => Promise<API.PageDataType<API.Team>>;
  };

  const createUser = async (params: API.AdminUserPostParamtersType) => {
    return postUser(params).then((res: any) => {
      return res;
    });
  };

  const deleteUser = async (params: API.AdminUserDeleteParamtersType) => {
    return deleteUserAPI(params).then((res: any) => {
      return res;
    });
  };

  const updateUser = async (params: API.AdminUserPutParamtersType) => {
    return putUser(params).then((res: any) => {
      return res;
    });
  };

  const createTeam = async (params: API.AdminTeamPostParamtersType) => {
    return postTeam(params).then((res: any) => {
      return res;
    });
  };

  const updateTeam = async (params: API.AdminTeamPutParamtersType) => {
    return putTeam(params).then((res: any) => {
      return res;
    });
  };

  const addTeamMember = async (params: API.AdminTeamMemberPutParamtersType) => {
    return putTeamAddMemeber(params).then((res: any) => {
      return res;
    });
  };

  const removeTeamMember = async (params: API.AdminTeamMemberPutParamtersType) => {
    return putTeamRemoveMemeber(params).then((res: any) => {
      return res;
    });
  };

  return {
    updateUser: promiseCompleteCall(updateUser, getAllUsers),
    createUser: promiseCompleteCall(createUser, getAllUsers),
    deleteUser: promiseCompleteCall(deleteUser, getAllUsers),
    createTeam: promiseCompleteCall(createTeam, getAllTeams),
    updateTeam: promiseCompleteCall(updateTeam, getAllTeams),
    addTeamMember: promiseCompleteCall(addTeamMember, getAllTeams),
    removeTeamMember: promiseCompleteCall(removeTeamMember, getAllTeams),
  };
};
