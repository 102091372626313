import { getUserAll, putUser } from '@/services/user';
import { useModel } from '@umijs/max';
import { useState } from 'react';

export default () => {
  const { initialState, setInitialState } = useModel('@@initialState');
  const [users, setUsers] = useState<API.PageLoading<API.User>>({
    page: 1,
    total: 1,
    data: [],
    loading: false,
  });
  const [allUsers, setAllUsers] = useState<API.StateLoading<API.User>>({
    data: [],
    loading: false,
  });

  const getUsers = async (params?: API.UserGetParamtersType) => {
    setUsers((prev) => ({ ...prev, loading: true }));
    return getUserAll(params)
      .then((res) => {
        setUsers((prev) => ({ ...prev, ...res }));
        return res;
      })
      .finally(() => {
        setUsers((prev) => ({ ...prev, loading: false }));
      });
  };

  const getAllUsers = async () => {
    setAllUsers((prev) => ({ ...prev, loading: true }));
    return getUserAll()
      .then((res) => {
        const { data } = res;
        setAllUsers((prev) => ({ ...prev, data }));
        return res;
      })
      .finally(() => {
        setAllUsers((prev) => ({ ...prev, loading: false }));
      });
  };

  const updateUserInfo = async (params: API.UpdateUserParams | API.ResetPasswordParams) => {
    return putUser(params).then((user) => {
      if (user?.id) {
        setInitialState({
          ...initialState,
          currentUser: user,
        });
      }
    });
  };

  return {
    // for user table
    getUsers,
    users,

    updateUserInfo,
    allUsers,
    getAllUsers,
  };
};
