export default function (initialState: { currentUser: { roles: API.User[] } }): {
  isDemo: boolean;
  isAdmin: boolean;
} {
  const isDemo = window.location.hostname.startsWith('demo');
  const isAdmin = !!initialState?.currentUser?.roles?.find(
    (role: { name: string }) => role?.name === 'admin',
  );
  return {
    isDemo,
    isAdmin,
  };
}
