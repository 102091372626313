import { getBudgetAllApi } from '@/services/budget';
import { useState } from 'react';

export default () => {
  const [budgetAll, setBudgetAll] = useState<API.PageDataType<API.Budget>>({
    page: 1,
    total: 1,
    data: [],
  });

  const getBudgetAll = async (params: API.BudgetGetParametersType = {}) => {
    return getBudgetAllApi(params).then((res) => {
      setBudgetAll(res);
      return res;
    });
  };

  return {
    budgetAll,
    getBudgetAll,
  };
};
