import {
  deleteContact as deleteContactAPI,
  getContactList as getContactListApi,
  postContact,
  putContact,
} from '@/services/contact';
import { promiseCompleteCall } from '@/utils/utils';
import { useState } from 'react';

export default () => {
  const [contactAll, setContactAll] = useState<API.PageDataType<API.Contact>>({
    page: 1,
    total: 1,
    data: [],
  });
  const [contactList, setContactList] = useState<API.PageLoading<API.Contact>>({
    page: 1,
    total: 1,
    data: [],
    loading: false,
  });

  const getContactAll = async (params: API.ContactGetParametersType = {}) => {
    return getContactListApi(params).then((res) => {
      setContactAll(res);
      return res;
    });
  };

  const getContactList = async () => {
    setContactList((prev) => ({ ...prev, loading: true }));
    return getContactListApi()
      .then((res) => {
        setContactList((prev) => ({ ...prev, ...res }));
        return res;
      })
      .finally(() => {
        setContactList((prev) => ({ ...prev, loading: false }));
      });
  };

  const createContact = async (params: API.ContactPostParametersType) => {
    return postContact(params).then((res) => {
      return res;
    });
  };

  const updateContact = async (params: API.ContactPutParametersType) => {
    return putContact(params).then((res) => {
      return res;
    });
  };

  const deleteContact = async (id: string) => {
    return deleteContactAPI(id).then((res) => {
      return res;
    });
  };

  return {
    getContactAll,
    contactAll,
    getContactList,
    contactList,
    createContact: promiseCompleteCall(createContact, getContactList),
    deleteContact: promiseCompleteCall(deleteContact, getContactList),
    updateContact: promiseCompleteCall(updateContact, getContactList),
  };
};
