type Objects = Record<string, string | number>;

export function pushDataLayer(config: Objects) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    ...config,
  });
}

export const pushLayerInit = () => {
  pushDataLayer({ event: 'init' });
};
