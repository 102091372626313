/* eslint-disable prefer-rest-params */
/* eslint-disable @typescript-eslint/no-unused-expressions */
export default function (GA_ID: string) {
  (function (id) {
    const script = document.createElement('script');
    (script.async = !0),
      (script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`),
      document.getElementsByTagName('head')[0].appendChild(script);
  })(GA_ID);
  window.dataLayer = window.dataLayer || [];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function gtag(..._: any[]) {
    window.dataLayer.push(arguments);
  }
  gtag('js', new Date());
  gtag('config', GA_ID);
}
