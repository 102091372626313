import {
  deleteBank as deleteBankApi,
  getBankList as getBankListApi,
  postBank,
  putBank,
} from '@/services/bank';
import { promiseCompleteCall } from '@/utils/utils';
import { useState } from 'react';

export default () => {
  const [banks, setBanks] = useState<API.PageDataType<API.Bank>>({
    page: 1,
    total: 1,
    data: [],
  });

  const [bankList, setBankList] = useState<API.PageLoading<API.Bank>>({
    page: 1,
    total: 1,
    data: [],
    loading: false,
  });

  const getBanks = async (params?: API.BankGetParamtersType) => {
    return getBankListApi(params).then((res) => {
      setBanks(res);
      return res;
    });
  };

  const getBankList = async () => {
    setBankList((prev) => ({ ...prev, loading: true }));
    return getBankListApi()
      .then((res) => {
        setBankList((prev) => ({ ...prev, ...res }));
        return res;
      })
      .finally(() => {
        setBankList((prev) => ({ ...prev, loading: false }));
      });
  };

  const createBank = async (params: API.BankPostParametersType) => {
    return postBank(params);
  };

  const updateBank = async (params: API.BankPutParametersType) => {
    return putBank(params);
  };

  const deleteBank = async (params: API.BankDeleteParametersTypes) => {
    return deleteBankApi(params);
  };

  return {
    getBanks,
    banks,
    bankList,
    createBank: promiseCompleteCall(createBank, getBankList),
    deleteBank: promiseCompleteCall(deleteBank, getBankList),
    updateBank: promiseCompleteCall(updateBank, getBankList),
    getBankList,
  };
};
