import { getTeamsApi } from '@/services/team';
import { uniqBy } from 'lodash';
import { useState } from 'react';

export default () => {
  const [teams, setTeams] = useState<API.PageDataType<API.Team>>({
    page: 1,
    total: 1,
    data: [],
  });
  const [allTeams, setAllTeams] = useState<API.StateLoading<API.Team>>({
    loading: false,
    data: [],
  });

  const getTeams = async (params: API.TeamGetParamtersType) => {
    return getTeamsApi(params).then((res) => {
      setTeams(res);
      return res;
    });
  };

  const getAllTeams = async () => {
    setAllTeams((prev) => ({ ...prev, loading: true }));
    return getTeamsApi()
      .then((res) => {
        const { data } = res;
        setAllTeams((prev) => ({ ...prev, data }));
        return res;
      })
      .finally(() => {
        setAllTeams((prev) => ({ ...prev, loading: false }));
      });
  };

  const allTeamsArray = allTeams?.data?.sort((a, b) => a?.name?.localeCompare(b?.name)) ?? [];

  const filterMembersFromSelectedTeams = (selectTeams: string[]): API.Member[] => {
    const members = uniqBy(
      allTeamsArray.flatMap((team) => {
        const isSelectTeam = selectTeams?.includes(team?.id);
        return isSelectTeam ? team?.members : [];
      }),
      'id',
    ).sort((a, b) => a?.name?.localeCompare(b?.name));
    return members;
  };

  return {
    // for team table
    getTeams,
    teams,

    getAllTeams,
    allTeams,
    allTeamsArray,
    filterMembersFromSelectedTeams,
  };
};
