import { getLoginUrl } from '@/utils/getSsoUrl';
import { removeObjectValueUndefinedNull } from '@/utils/objectHandler';
import { formatMessage } from '@umijs/max';
import { message } from 'antd';
import { camelize, camelizeKeys, decamelizeKeys } from 'humps';
import type { RequestConfig } from 'umi';
import { history } from 'umi';

import type { AxiosError, AxiosRequestConfig, AxiosResponse, RequestOptions } from '@umijs/max';

type requestConfig = AxiosRequestConfig & {
  skipMessage: number[];
  skipErrorMessage: boolean;
};

interface ExtendAxiosResponse extends AxiosResponse {
  config: AxiosResponse['config'] & { respSkipMiddleware?: boolean };
}

export const humpRegexOption = { split: /(?=[A-Z0-9])/ };

async function errorHandler(
  error: AxiosError<API.ErrorData> | Error,
  opts: RequestOptions,
): Promise<void> {
  if (!('response' in error) || !error.response) {
    return;
  }
  const { response } = error;

  const { skipMessage = [], skipErrorMessage } = response.config as requestConfig;
  if (response && response.status && !skipErrorMessage) {
    let errorMessage = formatMessage(
      { id: `error.${response.data?.error_code}` },
      response.data.detail as Record<string, number | string>,
    );
    if (response.data?.error_code === 422) {
      // fieldNamesMessage: { fieldName: string;index: string; } []
      const fieldNamesMessage = generateFieldError(response.data);
      // generate error content
      const getMessage = fieldNamesMessage?.reduce((str, nameMessage, index) => {
        const isLast = index === fieldNamesMessage.length - 1;
        const decorateSplit = isLast ? '' : ', ';
        return (
          str +
          `${nameMessage.index ? `${nameMessage.index}: ` : ''}${
            nameMessage.fieldName
          }${decorateSplit}`
        );
      }, '');
      errorMessage = formatMessage({ id: `error.422` }, { content: getMessage });
    }
    if (response.status === 401 && window.location.pathname !== '/login') {
      const { search } = history.location;
      history.push(`${getLoginUrl()}${search}`);
    }
    if (!skipMessage.includes(response.status)) {
      message.error(errorMessage, 3);
    }
  }

  if (!response) {
    message.error(`Network error: ${opts}`);
    console.error(opts);
  }

  // eslint-disable-next-line @typescript-eslint/no-throw-literal
  throw opts;
}

function prefixURL(url: string, options: RequestOptions) {
  if (url.indexOf('/api') !== 0) {
    return { url: `/api${url}`, options };
  }
  return { url, options };
}

function decamelizeRequest(url: string, options: RequestOptions) {
  const { params, data } = options;
  if (params) {
    options.params = decamelizeKeys(params, humpRegexOption);
  }
  if (
    data &&
    !(data instanceof URLSearchParams) &&
    !(typeof data === 'object' && data instanceof FormData)
  ) {
    options.data = decamelizeKeys(data, humpRegexOption);
  }
  return { url, options };
}

function removeUndefinedNullRequest(url: string, options: RequestOptions) {
  const { data } = options;
  if (
    data &&
    !(data instanceof URLSearchParams) &&
    !(typeof data === 'object' && data instanceof FormData)
  ) {
    options.data = removeObjectValueUndefinedNull(data);
  }

  return { url, options };
}

function camelizeResponse(response: ExtendAxiosResponse): AxiosResponse {
  if (response.config.respSkipMiddleware) {
    return response;
  }

  if (response.config.responseType === 'blob') {
    const contentDeposition = response.headers['content-disposition'];
    // content-disposition = `attachment; filename="xxxxx.png"` || `attachment; filename*=utf-8''%E9%8A%B7%E8%B2%A8%E5%96%AE.csv`
    // copy regex from https://stackoverflow.com/questions/40939380/how-to-get-file-name-from-content-disposition#comment113611680_40940790
    const fileNameArray = /filename\*?=([^']*'')?([^;]*)/.exec(contentDeposition) || [];
    response.data = {
      data: response.data,
      fileName: decodeURI(fileNameArray[2]).replace(/\"/g, ''),
    };
    return response;
  } else {
    response.data = camelizeKeys(response.data, humpRegexOption);
    return response;
  }
}

/**
 *This function is used to generate field errors. It takes in an "error" object and loops over each of its elements
 *
 *@param {API.ErrorData} error - The API error data object to generate the errors from
 *
 *@returns {error}  { fieldName: string; index: string; } []
 */
function generateFieldError(error: API.ErrorData) {
  return error.detail?.msg?.map((msg) => {
    const { fieldName, index } = msg.loc.reduce(
      (store, item) => {
        if (typeof item === 'number') {
          store.index = (store.index ? `${store.index}-` : '') + `${item + 1}`;
        } else {
          store.fieldName = camelize(item);
        }
        return store;
      },
      { fieldName: '', index: '' },
    );
    return { fieldName, index };
  });
}

export const request: RequestConfig = {
  errorConfig: {
    errorHandler,
  },
  requestInterceptors: [prefixURL, decamelizeRequest, removeUndefinedNullRequest],
  responseInterceptors: [camelizeResponse],
};
