/* eslint no-useless-escape:0 */
const urlReg =
  /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;

export const isUrl = (path: string): boolean => urlReg.test(path);

export const emailReg = '^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w+)+$';

export const formatMoney = ({
  value,
  fixed = 0,
  prefix = '$',
  replace,
}: {
  value: number | string | undefined | null;
  fixed?: number;
  prefix?: string;
  replace?: string;
}): string => {
  const intValue = parseFloat(`${value}`);
  return Number.isNaN(intValue)
    ? replace || ''
    : `${prefix} ${intValue.toLocaleString('en-US', { maximumFractionDigits: fixed })}`;
};

export const formatNumberDot = ({
  value,
  fixed = 0,
  replace,
}: {
  value: number | string | undefined;
  fixed?: number;
  replace?: string;
}) =>
  typeof value === 'number' && !Number.isNaN(value)
    ? value.toLocaleString('en-US', { maximumFractionDigits: fixed })
    : replace || '';

export function roundNumberToDecimal(num: number, fixed: number = 0) {
  const number = Math.round(num * Math.pow(10, fixed)) / Math.pow(10, fixed);
  return number;
}

export function getKeyByValue(object: object, value: any) {
  return Object.keys(object).find((key) => object[key] === value);
}

export function promiseCompleteCall<T, U, V>(
  promiseCall: { (params: T): Promise<V> },
  afterPromiseCall: () => Promise<U>,
) {
  return (params: T) => {
    return promiseCall(params).then((res) => {
      afterPromiseCall();
      return res;
    });
  };
}
