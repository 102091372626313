import { request } from 'umi';

export async function getAccountAllApi(params: API.AccountGetParametersType) {
  return request<API.PageDataType<API.Account>>('/account/all', {
    params,
  });
}

export async function getAccountListApi(params?: API.AccountGetParametersType) {
  return request<API.PageDataType<API.AccountList>>('/account/listing', {
    params,
  });
}

export async function deleteAccountApi(id: string) {
  return request('/account', {
    params: { id },
    method: 'delete',
  });
}

export async function postAccountApi(data: API.AccountPostParametersType) {
  return request('/account', {
    method: 'post',
    data,
  });
}

export async function putAccountApi(params: API.AccountPutParametersType) {
  const { id, ...data } = params;
  return request('/account', {
    method: 'put',
    params: { id },
    data,
  });
}

export async function syncAccountApi(params: API.AccountSyncGetParametersType) {
  return request('/account/sync', {
    params,
  });
}
