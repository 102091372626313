import { getAgencyApi } from '@/services/agency';
import { useState } from 'react';

export default () => {
  const [agency, setAgency] = useState<API.Agency>();
  const getAgency = async () => {
    return getAgencyApi().then((res) => {
      setAgency(res);
      return res;
    });
  };

  return {
    getAgency,
    agency,
  };
};
