import { decorateDateValueToUTC } from '@/utils/timeConvert';
import { request } from 'umi';

export async function getSpendingAll(params: API.SpendingGetParams) {
  return request<API.PageDataType<API.ReadAccountSpending>>('/account_spending/all', {
    method: 'get',
    params,
  });
}

export async function getSpendingSummary(params: API.SpendingGetParams) {
  return request<API.SpendingSummary>('/account_spending/summary', {
    method: 'get',
    params,
  });
}

export async function putSpendingTarget(data: API.SpendingPutParams, params: { id: string }) {
  return request('/spending_plan', {
    method: 'put',
    params,
    data,
  });
}

export async function postSpendingTarget(data: API.SpendingPostParams) {
  return request('/spending_plan', {
    method: 'post',
    data: decorateDateValueToUTC(data, ['start']),
  });
}

export async function getSpendingOverview(params: API.SpendingOverviewGetParams) {
  return request<API.SpendingOverview>('/account_spending/overview', {
    method: 'get',
    params,
  });
}

export async function getSpendingProgressOverview(params: API.SpendingProgressOverviewGetParams) {
  return request<API.SpendingProgressOverview>('/account_spending/progress_overview', {
    method: 'get',
    params,
  });
}

export async function downloadSpendingApi(params?: API.DownloadSpendingGetParametersType) {
  return request('/account_spending/download', {
    responseType: 'blob',
    params,
  });
}
