import {
  getSpendingAll,
  getSpendingOverview,
  getSpendingProgressOverview,
  getSpendingSummary,
  postSpendingTarget,
  putSpendingTarget,
} from '@/services/spending';
import { useState } from 'react';

export default () => {
  const [spendings, setSpendings] = useState<API.PageDataType<API.ReadAccountSpending>>({
    page: 1,
    total: 1,
    data: [],
  });
  const [spendingSummary, setSpendingSummary] = useState<API.SpendingSummary>();
  const [spendingOverview, setSpendingOverview] = useState<API.SpendingOverview>();
  const [spendingProgressOverview, setSpendingProgressOverview] =
    useState<API.SpendingProgressOverview>();

  const fetchAllSpendings = async (params: API.SpendingGetParams) => {
    return getSpendingAll(params).then((res) => {
      setSpendings(res);
    });
  };

  const fetchSpendingSummary = async (params: API.SpendingGetParams) => {
    return getSpendingSummary(params).then((res) => {
      setSpendingSummary(res);
    });
  };

  const updateSpendingTarget = async (data: API.SpendingPutParams, id: string) => {
    return putSpendingTarget(data, { id }).then((res) => {
      return res;
    });
  };

  const createSpendingTarget = async (data: API.SpendingPostParams) => {
    return postSpendingTarget(data).then((res) => {
      return res;
    });
  };

  const fetchSpendingOverview = async (params: API.SpendingOverviewGetParams) => {
    return getSpendingOverview(params).then((res) => {
      setSpendingOverview(res);
    });
  };

  const fetchSpendingProgressOverview = async (params: API.SpendingProgressOverviewGetParams) => {
    return getSpendingProgressOverview(params).then((res) => {
      setSpendingProgressOverview(res);
    });
  };

  return {
    spendings,
    spendingSummary,
    spendingOverview,
    spendingProgressOverview,
    fetchAllSpendings,
    fetchSpendingSummary,
    fetchSpendingOverview,
    fetchSpendingProgressOverview,
    updateSpendingTarget,
    createSpendingTarget,
  };
};
