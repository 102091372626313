import { request } from 'umi';

export async function postLogin(body: API.LoginParametersType) {
  return request('/auth/login', {
    method: 'post',
    data: new URLSearchParams(body),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
}

export async function postLogout() {
  return request('/auth/logout', {
    method: 'post',
  });
}

export async function postForgetPassword(data: API.ForgetPasswordPostParametersType) {
  return request('/auth/forgot-password', {
    method: 'post',
    data,
  });
}

export async function postResetPassword(data: API.ResetPasswordPostParametersType) {
  return request('/auth/reset-password', {
    method: 'post',
    data,
  });
}
