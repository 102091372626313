import {
  deleteOrder as deleteOrderAPI,
  downloadOrderList as downloadOrderListAPI,
  getOrderList,
  postOrder,
  putOrder,
  putOrderReview,
  putOrderSubmit,
  putOrderTransation,
} from '@/services/order';
import { promiseCompleteCall } from '@/utils/utils';
import { useState } from 'react';
import { useModel } from 'umi';

export default () => {
  const [orders, setOrders] = useState<API.PageDataType<API.Order>>({
    page: 1,
    total: 1,
    data: [],
  });

  // The type linter will throw error. It cann't directly destructive and use.
  // Function implicitly has return type 'any' because it does not have a return type annotation and is referenced directly or indirectly in one of its return expressions.ts
  const transactionModel = useModel('useTransaction');
  const { getTransactionListNotOrdered } = transactionModel;

  const getOrders = async (params: API.OrderGetParametersType) => {
    return getOrderList(params).then((res) => {
      setOrders(res);
      return res;
    });
  };

  const updateOrder = async (params: API.OrderPutParametersType) => {
    return putOrder(params).then((res) => {
      return res;
    });
  };

  const createOrder = async (params: API.OrderPostParametersType) => {
    return postOrder(params).then((res) => {
      return res;
    });
  };

  const deleteOrder = async (id: string) => {
    return deleteOrderAPI(id).then((res) => {
      return res;
    });
  };

  const updateOrderTransaction = async (
    params: API.OrderTransactionPutParametersType,
    id: string,
  ) => {
    return putOrderTransation(params, id).then((res) => {
      return res;
    });
  };

  const submitOrder = async (params: API.OrderReviewPutParametersType, id: string) => {
    return putOrderSubmit(params, id).then((res) => {
      return res;
    });
  };

  const reviewOrder = async (params: API.OrderReviewPutParametersType, id: string) => {
    return putOrderReview(params, id).then((res) => {
      return res;
    });
  };

  const downloadOrderList = async (params: API.OrderDownloadParametersType) => {
    return downloadOrderListAPI(params).then((res) => {
      return res;
    });
  };

  return {
    getOrders,
    orders,
    createOrder: promiseCompleteCall(createOrder, getTransactionListNotOrdered),
    updateOrder: promiseCompleteCall(updateOrder, getTransactionListNotOrdered),
    deleteOrder: promiseCompleteCall(deleteOrder, getTransactionListNotOrdered),
    updateOrderTransaction,
    submitOrder,
    reviewOrder,
    downloadOrderList,
  };
};
