import { request } from 'umi';

export async function getBudgetAllApi(params: API.BudgetGetParametersType) {
  return request<API.PageDataType<API.Budget>>('/budget/all', {
    params,
  });
}

export async function downloadBudgetApi(params?: API.DownloadBudgetGetParametersType) {
  return request('/budget/download', {
    responseType: 'blob',
    params,
  });
}
