import { request } from 'umi';

import { convertTodayToCurrentTimeUTC, decorateDateValueToUTC } from '@/utils/timeConvert';

export async function getDeposits(params: API.DepositGetParametersType) {
  let overwriteEndDate = {};
  if (params?.end) {
    const convertEndDate = convertTodayToCurrentTimeUTC(params?.end);
    if (convertEndDate) {
      overwriteEndDate = { end: convertEndDate };
    }
  }
  return request<API.PageDataType<API.Deposit>>('/dp/all', {
    params: { ...decorateDateValueToUTC(params, ['start', 'end']), ...overwriteEndDate },
  });
}

export async function getDeposit(id: string) {
  return request<API.Deposit>('/dp', { params: { id } });
}
export async function postDeposits(data: API.DepositPostParametersType[]) {
  return request('/dp/bulk_create', {
    method: 'post',
    data,
  });
}

export async function deleteDeposit(id: string) {
  return request('/dp', {
    method: 'delete',
    params: { id },
  });
}

export async function putDeposit(
  data: API.DepositPostParametersType | API.DepositStatusPutParametersType,
  id: string,
  skipErrorMessage?: boolean,
) {
  return request<API.Deposit>(`/dp`, {
    method: 'put',
    params: { id },
    data,
    skipErrorMessage,
  });
}

export async function putDepositSubmit(
  data: API.DepositSubmitPutParametersType,
  id: string,
  skipErrorMessage?: boolean,
) {
  return request<API.Deposit>('/dp/submit', {
    method: 'put',
    params: { id },
    data,
    skipErrorMessage,
  });
}

export async function putDepositReview(
  data: API.DepositReviewPutParametersType,
  id: string,
  skipErrorMessage?: boolean,
) {
  return request<API.Deposit>('/dp/review', {
    method: 'put',
    params: { id },
    data,
    skipErrorMessage,
  });
}

export async function downloadDepositList(params: API.OrderDownloadParametersType) {
  const endDateIsCurrentDate = params?.end && params?.end.isSame(new Date(), 'day');
  const overwriteEndDate =
    params?.end && endDateIsCurrentDate ? { end: params?.end.utc().format() } : {};
  return request<API.DownloadType>('/dp/download/csv', {
    responseType: 'blob',
    params: { ...decorateDateValueToUTC(params, ['start', 'end']), ...overwriteEndDate },
  });
}

export const postDpConfirmEmail = async (params: API.DpPostEmailParametersType) => {
  const { id, toEmails, note } = params;
  return request('/dp/confirm_email', {
    method: 'post',
    params: { id },
    data: { toEmails, note },
  });
};
