import { getTransactionList } from '@/services/transaction';
import { useState } from 'react';

export default () => {
  const [transactions, setTransactions] = useState<API.PageDataType<API.Transaction>>({
    page: 1,
    total: 1,
    data: [],
  });
  const [transactionListNotOrdered, setTransactionListNotOrdered] = useState<
    API.PageLoading<API.Transaction>
  >({
    page: 1,
    total: 1,
    data: [],
    loading: false,
  });

  const getTransactions = async (params: API.TransactionGetParametersType) => {
    return getTransactionList(params).then((res) => {
      setTransactions(res);
      return res;
    });
  };

  const getTransactionListNotOrdered = async () => {
    setTransactionListNotOrdered((prev) => ({ ...prev, loading: true }));
    return getTransactionList({ status: 'not-ordered' })
      .then((res) => {
        setTransactionListNotOrdered((prev) => ({ ...prev, ...res }));
        return res;
      })
      .finally(() => {
        setTransactionListNotOrdered((prev) => ({ ...prev, loading: false }));
      });
  };

  return {
    getTransactions,
    transactions,
    transactionListNotOrdered,
    getTransactionListNotOrdered,
  };
};
