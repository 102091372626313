import useInitUser from '@/hooks/useInitUser';
import { useEffect } from 'react';
import { useModel } from 'umi';

const FetchListingContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { getCompanyList } = useModel('useCompany');
  const { getAccountList } = useModel('useAccount');
  const { getBankList } = useModel('useBank');
  const { getContactList } = useModel('useContact');
  const { getAllTeams } = useModel('useTeam');
  const { getTransactionListNotOrdered } = useModel('useTransaction');
  const { getAllUsers } = useModel('useUser');
  const { getAllRoles } = useModel('useRole');
  const { getAgency } = useModel('useAgency');

  useEffect(() => {
    getCompanyList();
    getAccountList();
    getBankList();
    getContactList();
    getTransactionListNotOrdered();
    getAllTeams();
    getAllUsers();
    getAllRoles();
    getAgency();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useInitUser();
  return <>{children}</>;
};

export default FetchListingContainer;
