import { request } from 'umi';

import {
  convertTodayToCurrentTimeUTC,
  decorateDateValueToUTC,
  formatStartEndDate,
} from '@/utils/timeConvert';

export async function getTransactionList(params: API.TransactionGetParametersType) {
  let overwriteEndDate = {};
  if (params?.end) {
    const convertEndDate = convertTodayToCurrentTimeUTC(params?.end);
    if (convertEndDate) {
      overwriteEndDate = { end: convertEndDate };
    }
  }
  const formatDataParams = formatStartEndDate(
    {
      ...decorateDateValueToUTC(params, ['start', 'end']),
      ...overwriteEndDate,
    },
    'YYYY-MM-DD',
  );
  return request<API.PageDataType<API.Transaction>>('/transaction/all', {
    params: {
      ordering: '-slug',
      ...formatDataParams,
    },
  });
}
