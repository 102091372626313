import {
  getNotificationAll,
  postNotificationReport,
  putNotificationDeficit,
  putNotificationReport,
} from '@/services/notification';
import { useState } from 'react';

export default () => {
  const [notifications, setNotifications] = useState<API.PageDataType<API.NotificationSummary>>({
    page: 1,
    total: 1,
    data: [],
  });

  const fetchAllNotification = async (params: API.NotificationGetParams) => {
    return getNotificationAll(params).then((res) => {
      setNotifications(res);
    });
  };

  const updateNotificationDeficit = async (data: API.NotificationDeficitPutParams, id: string) => {
    return putNotificationDeficit(data, { id }).then((res) => {
      return res;
    });
  };

  const updateNotificationReport = async (data: API.NotificationReportPutParams, id: string) => {
    return putNotificationReport(data, { id }).then((res) => {
      return res;
    });
  };

  const sendNotificationReport = async (data: API.NotificationReportPostParams, id: string) => {
    return postNotificationReport(data, { id }).then((res) => {
      return res;
    });
  };

  return {
    notifications,
    fetchAllNotification,
    updateNotificationDeficit,
    updateNotificationReport,
    sendNotificationReport,
  };
};
