import { request } from 'umi';

export async function getContactList(params?: API.ContactGetParametersType) {
  return request<API.PageDataType<API.Contact>>('/contact/all', { params });
}

export async function postContact(data: API.ContactPostParametersType) {
  return request('/contact', {
    method: 'post',
    data,
  });
}

export async function deleteContact(id: string) {
  return request('/contact', {
    method: 'delete',
    params: { id },
  });
}

export async function putContact(params: API.ContactPutParametersType) {
  const { id, ...data } = params;
  return request('/contact', {
    method: 'put',
    params: { id },
    data,
  });
}
