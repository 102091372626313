import { getReportTypeListApi } from '@/services/system';
import { useState } from 'react';

export default () => {
  const [reportTypeList, setReportTypeList] = useState<API.StateLoading<API.SystemReportType>>({
    data: [],
    loading: false,
  });

  const getReportTypeList = async () => {
    setReportTypeList((prev) => ({ ...prev, loading: true }));
    return getReportTypeListApi()
      .then((res) => {
        setReportTypeList((prev) => ({ ...prev, data: res }));
        return res;
      })
      .finally(() => {
        setReportTypeList((prev) => ({ ...prev, loading: false }));
      });
  };

  return {
    getReportTypeList,
    reportTypeList,
  };
};
