import {
  deleteDeposit as deleteDepositAPI,
  downloadDepositList as downloadDepositListAPI,
  getDeposits as getDepositsAPI,
  postDeposits,
  putDeposit,
  putDepositReview,
  putDepositSubmit,
} from '@/services/deposit';
import { useState } from 'react';

export default () => {
  const [deposits, setDeposits] = useState<API.PageDataType<API.Deposit>>({
    page: 1,
    total: 1,
    data: [],
  });

  const getDeposits = async (params: API.DepositGetParametersType) => {
    return getDepositsAPI(params).then((res) => {
      setDeposits(res);
      return res;
    });
  };

  const createDeposits = async (params: API.DepositPostParametersType[]) => {
    return postDeposits(params).then((res) => {
      return res;
    });
  };

  const updateDeposit = async ({
    params,
    id,
    skipErrorMessage,
  }: {
    params: API.DepositPostParametersType | API.DepositStatusPutParametersType;
    id: string;
    skipErrorMessage?: boolean;
  }) => {
    return putDeposit(params, id, skipErrorMessage).then((res) => {
      return res;
    });
  };

  const submitDeposit = async ({
    params,
    id,
    skipErrorMessage,
  }: {
    params: API.DepositReviewPutParametersType;
    id: string;
    skipErrorMessage?: boolean;
  }) => {
    return putDepositSubmit(params, id, skipErrorMessage).then((res) => {
      return res;
    });
  };

  const reviewDeposit = async ({
    params,
    id,
    skipErrorMessage,
  }: {
    params: API.DepositReviewPutParametersType;
    id: string;
    skipErrorMessage?: boolean;
  }) => {
    return putDepositReview(params, id, skipErrorMessage).then((res) => {
      return res;
    });
  };

  const deleteDeposit = async (id: string) => {
    return deleteDepositAPI(id).then((res) => {
      return res;
    });
  };

  const downloadDepositList = async (params: API.DepositDownloadParametersType) => {
    return downloadDepositListAPI(params).then((res) => {
      return res;
    });
  };

  return {
    getDeposits,
    deposits,
    createDeposits,
    updateDeposit,
    deleteDeposit,
    submitDeposit,
    reviewDeposit,
    downloadDepositList,
  };
};
