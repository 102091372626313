import { getUser } from '@/services/user';
import { filterByTeamsPermission, getUserResourcePermission } from '@/utils/featureMappingRole';
import { history, useModel } from 'umi';

export default () => {
  const { initialState, setInitialState } = useModel('@@initialState');

  const fetchUser = async () => {
    return getUser()
      .then(async (user) => {
        if (user) {
          const role = getUserResourcePermission(user?.roles || []);
          const filterRole = filterByTeamsPermission(role, user?.teams);
          setInitialState({
            ...initialState,
            currentUser: user,
            role: filterRole,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  const loginOnSuccess = () => {
    fetchUser().then(() => {
      history.push('/budget/account');
    });
  };

  return {
    fetchUser,
    loginOnSuccess,
  };
};
