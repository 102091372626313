export type ActionType = 'canCreate' | 'canRead' | 'canUpdate' | 'canDelete' | 'canReview';
export type ResourcesName =
  | 'company'
  | 'account'
  | 'order'
  | 'user'
  | 'deposit'
  | 'receipt'
  | 'transaction'
  | 'bank';

export type ResourcesRoleActions = {
  company: ActionType[];
  account: ActionType[];
  order: ActionType[];
  user: ActionType[];
  deposit: ActionType[];
  receipt: ActionType[];
  transaction: ActionType[];
  bank: ActionType[];
};

export const create = 'canCreate';
export const read = 'canRead';
export const update = 'canUpdate';
export const review = 'canReview';
export const deleted = 'canDelete';

const adminResources: ResourcesRoleActions = {
  company: [create, read, update, deleted],
  account: [create, read, update, deleted],
  order: [create, read, update, deleted, review],
  user: [create, read, update, deleted],
  deposit: [create, read, update, deleted, review],
  receipt: [create, read],
  transaction: [read],
  bank: [create, read, update, deleted],
};

const roles: Record<API.RoleType, ResourcesRoleActions> = {
  admin: adminResources,
  am: {
    company: [create, read, update, deleted],
    account: [create, read, update, deleted],
    order: [create, read, update, deleted],
    user: [create, read, update, deleted],
    deposit: [create, read, update, deleted],
    receipt: [read],
    transaction: [read],
    bank: [create, read, update, deleted],
  },
  'am-lead': {
    company: [create, read, update, deleted],
    account: [create, read, update, deleted],
    order: [create, read, update, deleted],
    user: [create, read, update, deleted],
    deposit: [create, read, update, deleted],
    receipt: [read],
    transaction: [read],
    bank: [create, read, update, deleted],
  },
  'am-assistant': {
    company: [create, read, update, deleted],
    account: [read],
    order: [create, read, update, deleted],
    user: [create, read, update, deleted],
    deposit: [read],
    receipt: [create, read],
    transaction: [read],
    bank: [create, read, update, deleted],
  },
  accounting: {
    company: [read],
    account: [read],
    order: [read, review],
    user: [read, update],
    deposit: [read, review],
    receipt: [create, read],
    transaction: [read],
    bank: [create, read, update, deleted],
  },
  ae: {
    company: [read, update],
    account: [read, update],
    order: [read],
    user: [read],
    deposit: [read],
    receipt: [read],
    transaction: [read],
    bank: [read],
  },
  'ae-lead': {
    company: [read],
    account: [read],
    order: [read, review],
    user: [read, update],
    deposit: [read, review],
    receipt: [read],
    transaction: [read],
    bank: [read],
  },
};

const defaultRoles = {
  company: [],
  account: [],
  order: [],
  user: [],
  deposit: [],
  receipt: [],
  transaction: [],
  bank: [],
};

export const getUserResourcePermission = (getRoles: API.RoleShort[]): ResourcesRoleActions => {
  return getRoles
    .map((role) => role.name)
    .reduce(
      (collection, role) => {
        const resourcesRole = roles[role];
        Object.keys(resourcesRole).forEach((featureKey) => {
          collection[featureKey] = [
            ...new Set([...collection[featureKey], ...resourcesRole[featureKey]]),
          ];
        });

        return collection;
      },
      { ...defaultRoles },
    );
};

export const filterByTeamsPermission = (
  getRoles: ResourcesRoleActions,
  teams: API.TeamSummary[] = [],
): ResourcesRoleActions => {
  return Object.keys(getRoles).reduce(
    (role, key) => {
      let currentSelectValue = role[key];
      if (key === 'order') {
        const isExternalAm =
          teams.length > 0 &&
          teams.every((team) => {
            return team.internal === false;
          });
        if (isExternalAm) {
          currentSelectValue = currentSelectValue.filter(
            (action: ActionType[number]) => action !== update && action !== deleted,
          );
        }
      }
      return {
        ...role,
        [key]: currentSelectValue,
      };
    },
    { ...getRoles },
  );
};

export const roleMappingi18n = {
  am: `org.user.role.AM`,
  ae: `org.user.role.AE`,
  admin: `org.user.role.admin`,
  accounting: `org.user.role.accounting`,
  'am-assistant': `org.user.role.AMassistant`,
};

export default roles;
