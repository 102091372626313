import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/en'; // Import any other locale you need
import utcPlugin from 'dayjs/plugin/utc';

dayjs.extend(utcPlugin);

type InternalNamePath = string[];
type NamePath = string | InternalNamePath;

export const utcConvertLocalDate = (date: string) => {
  const stillUtc = dayjs.utc(date).toDate();
  const localDate = dayjs(stillUtc).locale('en');
  return localDate;
};

export const localeDateConvertUtc = (
  date: Dayjs | null | string,
  schema: string = 'YYYY-MM-DD HH:mm:ss',
) => {
  if (date && typeof date !== 'string' && dayjs(date).isValid()) {
    return dayjs(date.format(schema)).utc().format();
  }
  return date;
};

// when the target is nest schema, you should use pathName[] in array.
// i.e: { parent: { target: Date } } keys -> [['parent', 'target']]
export function decorateDateValueToUTC<T extends object>(
  data: T,
  keys: NamePath[],
  schema?: string,
) {
  return keys.reduce((obj: T, key: string | InternalNamePath) => {
    if (typeof key === 'string' && key in obj) {
      return { ...obj, [key]: localeDateConvertUtc(obj[key], schema) };
    }
    if (Array.isArray(key)) {
      const { ...copyObj } = obj;
      updateDeepProperty(copyObj, key, localeDateConvertUtc);
      return copyObj;
    }
    return obj;
  }, data);
}

// it will directly update obj value.
function updateDeepProperty<T>(
  obj: T,
  keys: InternalNamePath,
  callback: (date: Dayjs, schema?: string) => string | Dayjs | null,
) {
  let current = obj;
  // current will be reassign as inside object.
  // i.e: { a : { b : c: true } } -> { b: { c: value } }
  for (let depth = 0; depth < keys.length - 1; depth++) {
    if (current[keys[depth]]) {
      current = current[keys[depth]];
    }
  }
  const selectLastKey = keys[keys.length - 1];
  // it will find our target key and reassign value.
  // i.e: { c: value } ->  { c: Callback(value) }
  if (current[selectLastKey]) {
    current[selectLastKey] = callback(current[selectLastKey]);
  }
}

export function convertTodayToCurrentTimeUTC(inputDate: Dayjs) {
  const endDateIsCurrentDate = inputDate && inputDate.isSame(new Date(), 'day');
  const overwriteEndDate = endDateIsCurrentDate && dayjs().utc().format();
  return overwriteEndDate || null;
}

export function formatStartEndDate(
  obj: Record<string, string | number | Dayjs | Date | null | undefined>,
  schema = 'YYYY-MM-DD',
) {
  const result = {};
  Object.keys(obj).forEach((key) => {
    if (key === 'start' || key === 'end') {
      result[key] = dayjs(obj[key]).format(schema);
    }
  });
  return { ...obj, ...result };
}
