import { request } from 'umi';

export async function getCompanyAll(params: API.CompanyListGetParametersType) {
  return request<API.PageDataType<API.Company>>('/company/all', { params });
}

export async function getCompanyList(params?: API.CompanyListGetParametersType) {
  return request<API.PageDataType<API.CompanyList>>('/company/listing', { params });
}

export async function getCompany(params: API.CompanyGetParametersType) {
  return request<API.Company>('/company', { params });
}

export async function postCompany(data: API.CompanyPostParametersType) {
  return request('/company', {
    method: 'post',
    data,
  });
}

export async function deleteCompany(id: string) {
  return request('/company', {
    method: 'delete',
    params: { id },
  });
}

export async function putCompany(params: API.CompanyPutParametersType) {
  const { id, ...data } = params;
  return request('/company', {
    method: 'put',
    params: { id },
    data,
  });
}
