import { getCommonVariables } from '@/tracking/commonEvent';
import { pushDataLayer } from './dataLayer/initEvent';

/* eslint-disable @typescript-eslint/no-unused-expressions */
declare global {
  interface Window {
    analytics: any;
    dataLayer: any;
  }
}
// reset segment track info
const resetTrackSession = () => {
  pushDataLayer({ event: 'logout', ...getCommonVariables() });
};

const pushIdentifyUserLogin = () => {
  pushDataLayer({ event: 'login', ...getCommonVariables() });
};

export { resetTrackSession, pushIdentifyUserLogin };
