import { request } from 'umi';

export async function getBankList(params?: API.BankGetParamtersType) {
  return request<API.PageDataType<API.Bank>>('/bank_account/all', { params });
}

export async function postBank(data: API.BankPostParametersType) {
  return request('/bank_account', {
    method: 'post',
    data,
  });
}

export async function putBank(params: API.BankPutParametersType) {
  const { id, ...data } = params;
  return request('/bank_account', {
    method: 'put',
    params: { id },
    data,
  });
}

export async function deleteBank(params: API.BankDeleteParametersTypes) {
  return request('/bank_account', { method: 'delete', params });
}
